<template>
    <div class="detail-wrap">
        <el-breadcrumb separator="/" class="crumbs">
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
                <i class="el-icon-s-home"></i>
                首页
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/promotion/projectList' }">项目帮扶列表</el-breadcrumb-item>
            <el-breadcrumb-item class="path-help">{{ detailData.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="detail-body" v-loading="loading">
            <div class="detail-top">
                <div class="detail-left">
                    <div class="projectsupport-container" v-if="detailData && detailData.status && detailData.status.status && detailData.status.text">
                        <div class="img-container">
                            <img :src="detailData.thumbnail" alt="" />
                            <span v-if="detailData.status.status == 3" class="span_1">{{ detailData.status.text }}</span>
                            <span v-if="detailData.status.status == 1" class="span_2">{{ detailData.status.text }}</span>
                            <span v-if="detailData.status.status == 2" class="span_3">{{ detailData.status.text }}</span>
                        </div>
                    </div>
                </div>
                <div class="detail-right">
                    <div class="de-ri-title">
                        <label :class="(detailData.project_type == 1) ? 'gray' : (detailData.project_type == 2?'red':'yellow')"
                         v-if="detailData && detailData.project_type && detailData.project_type.text">
                            {{ detailData.project_type.text }}
                        </label>
                        {{ detailData.title }}
                    </div>
                    <div class="de-ri-amount">¥{{ detailData.project_price }}</div>
                    <div class="de-ri-item">
                        <span class="dri-lable">项目类型：</span>
                        <span class="dri-body" v-if="detailData && detailData.project_type && detailData.project_type.text">{{ detailData.project_type.text }}</span>
                    </div>
                    <div class="de-ri-item">
                        <span class="dri-lable">帮扶对象：</span>
                        <span class="dri-body">{{ detailData.help_object }}</span>
                    </div>
                    <div class="de-ri-item">
                        <span class="dri-lable">项目地址：</span>
                        <span class="dri-body" v-if="detailData && detailData.province_id && detailData.province_id.text && detailData.city_id && detailData.city_id.text && detailData.twon_id && detailData.twon_id.text">
                          {{ detailData.province_id.text}}{{ detailData.city_id.text}}{{ detailData.twon_id.text}}
                          </span>
                    </div>
                    <div class="de-ri-item">
                        <span class="dri-lable">发布时间：</span>
                        <span class="dri-body">{{ detailData.create_time }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail-body" v-loading="loading">
            <div class="detail-conter">
                <div class="decon-top">
                    <p>项目详情</p>
                </div>
                <div class="region_detail" v-html="detailData.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {projectDetail} from "@/api/pc";

    export default {
        components: {},
        name: "project_detail",
        data: () => {
            return {
                id:null,
                loading: false,
                detailData: {}
            }
        },
        created() {
            this.id = this.$route.path.replace('/promotion/projectList/detail-', '');
            this.getDetail();
        },
        methods: {
            getDetail() {
                projectDetail({
                  id: this.id
                }).then(res => {
                      if (res.code == 10000) {
                          if (res.result) {
                              this.loading = false;
                              this.detailData = res.result;
                              let arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
                              this.detailData.content = this.detailData.content.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
                          } else {
                              this.$router.push({ path: '/promotion/projectList' });
                          }
                      }
                  })
                  .catch(err => {
                      this.loading = false;
                      this.$message.error(err.message);
                  });
            },
        }
    }
</script>
<style lang="scss">
    .el-carousel__arrow--left {
        left: 150px;
    }
    .el-carousel__arrow--right {
        right: 150px;
    }
    .banner-wrap {
        width: 100%;
        height: 300px;
        margin: 0;
        padding: 0;
        position: relative;
        img {
            height: 300px;
            width: 100%;
        }
    }
    .crumbs {
        width: 1210px;
        margin: 30px auto;
        position: relative;
        padding: 0;
    }
    .detail-body {
        background-color: #ffffff;
        padding: 40px;
        border-radius: 8px;
        margin: 0 auto;
        margin-bottom: 40px;

        width: 1130px;
        .detail-top {
            width: 1130px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .detail-left {
                width: 560px;
                height: 334px;
                margin-right: 42px;

                .projectsupport-container {
                    width: 100%;
                    box-sizing: border-box;
                    transition: all 0.1s;

                    .img-container {
                        display: block;
                        width: 100%;
                        height: 334px !important;
                        object-fit: cover;
                        position: relative;
                        box-sizing: border-box;
                        border-radius: 2px !important;
                        border: 1px solid $project-item-border-color !important;

                        img {
                            width: 100%;
                            box-sizing: border-box;
                            height: 334px !important;
                            border-radius: 2px !important;
                        }

                        span {
                            box-sizing: border-box;
                            position: absolute;
                            top: -8px;
                            left: 15px;
                            display: block;
                            padding: 6px 12px;
                            font-size: 14px;
                            color: #fff;
                            line-height: normal;
                            letter-spacing: 0;
                            text-shadow: 0 1px 1px rgb(102 102 102 / 50%);
                            z-index: 2;
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        }
                        .span_1 {
                            background: $project-item-type-color-1 !important;
                        }
                        .span_2 {
                            background: $project-item-type-color-2 !important;
                        }
                        .span_3 {
                            background: $project-item-type-color-3 !important;
                        }

                        span::after {
                            content: "";
                            position: absolute;
                            left: -8px;
                            top: 0;
                            border: 4px solid #89540c;
                            border-left-color: transparent;
                            border-top-color: transparent;
                        }
                    }
                }
            }
            .detail-right {
                width: 489px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                box-sizing: border-box;

                .de-ri-title {
                    font-size: 24px;
                    color: $project-item-title-color;
                    line-height: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    word-break: break-all;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    box-sizing: border-box;
                    font-weight: 600;

                    label {
                        height: 24px;
                        font-size: 12px;
                        line-height: 24px;
                        color: #fff;
                        padding: 0px 8px 0px 6px;
                        margin-right: 5px;
                        border-radius: 6px;
                        display: inline-block;
                        margin-top: 6px;
                        margin-bottom: 6px;
                    }
                    label.red {
                        background-color: $base-color;
                    }
                    label.gray {
                        background-color: #8096b3;
                    }
                    label.yellow {
                        background-color: #ff850f;
                    }
                }

                .de-ri-amount {
                    font-size: 48px;
                    line-height: 66px;
                    height: 66px;
                    width: 100%;
                    box-sizing: border-box;
                    color: $base-color;
                    margin-top: 12px;
                    margin-bottom: 4px;
                }

                .de-ri-item {
                    width: 489px;
                    box-sizing: border-box;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;

                    .dri-lable {
                        width: 88px;
                        color: $project-item-font-color-lable;
                    }
                    .dri-body {
                        width: 398px;
                        color: $project-item-font-color-main;
                    }
                }
            }
        }
        .detail-conter {
            width: 1130px;
            box-sizing: border-box;

            .decon-top {
                width: 100%;
                height: 60px;
                box-sizing: border-box;
                border-bottom: 4px solid $base-color;
                margin-bottom: 20px;

                p {
                    width: 230px;
                    box-sizing: border-box;
                    padding: 8px 16px;
                    height: 60px;
                    line-height: 44px;
                    background-color: $base-color;
                    color: $base-color-f;
                    text-align: center;
                    font-size: 24px;
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }
            }

            .region_detail {
                box-sizing: border-box;
                width: 100%;
                line-height: 32px;
            }
        }
    }
</style>
